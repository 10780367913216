import { useContext, useEffect, useState }  from "react";
import GenericTemplate from "../templates/GenericTemplate";
import { Box } from "@material-ui/core";
import ShiftTableHeader from "../conponents/ShiftTableHeader";
import { GlobalContext } from "../globalState";
import * as commonFnc from '../common/commonFnc';
import { DataGrid } from "@mui/x-data-grid";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from "@mui/material";
import '../css/Loading.css';
import SP_ShiftTableHeader from "./SP_ShiftTableHeader";

const ShiftTableContents = (props) =>{
    const {Login_user_info} = props;
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    
    const {serchCondition, setSerchCondition} = useContext(GlobalContext);
    const Env_msts = useContext(GlobalContext).Env_msts;
    const TIME_PAID_HOLIDAY_FLG = Env_msts.find(p => p.env_key === "TIME_PAID_HOLIDAY")?.env_bool;

    const [Department_msts, setDepartment_msts] = useState([]);
    const [Shift_msts, setShift_msts] = useState([]);
    const [Users, setUsers] = useState([]);
    const [kintaiList, setKintaiList] = useState([]);

    const [shiftHash, setShiftHash] = useState([]);
    const [shiftStyleList, setShiftStyleList] = useState([]);
    const [userHash, setUserHash] = useState([]);
    const [deptHash, setDeptHash] = useState([]);
    
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);

    const [holidays, setHolidays] = useState({});
    
    const [isViewDept, setIsViewDept] = useState(false);
    const [isViewPaidHoliday, setIsViewPaidHoliday] = useState(false);


    const [elements, setElements] = useState(TIME_PAID_HOLIDAY_FLG?['shift_type_cd','harf_holiday_shift_type_cd','paid_holiday_shift_type_cd']:['shift_type_cd','harf_holiday_shift_type_cd']);

    
    

    useEffect(()=>{
        if(isViewPaidHoliday){
            setElements(['shift_type_cd','harf_holiday_shift_type_cd','paid_holiday_shift_type_cd']);
        }else{
            setElements(['shift_type_cd','harf_holiday_shift_type_cd']);
        }

    },[isViewPaidHoliday])

    
    
    useEffect(()=>{
        fetch('https://holidays-jp.github.io/api/v1/date.json')
        .then((res) => res.json())
        .then((data)=>setHolidays(data));
    },[])
    
    // 部署マスタ一覧取得
    const getDepartmentMst = async () =>{
        const res = await fetch("/api/select/department_msts");
        const data = await res.json();
        setDepartment_msts(data);
    }
    // シフトマスタ一覧取得
    const getShiftMst = async ()=>{
        const res = await fetch("/api/select/shift_mst");
        const data  = await res.json();
        setShift_msts(data);   
    }
    // シフトマスタ一覧取得
    const getUsers = async ()=>{
        const res = await fetch("/api/select/users");
        const data  = await res.json();
        setUsers(data);   
    }
    // 全ユーザの1か月の勤怠情報を取得
    const getKintaiList = async () => {
        const query_kintai = new URLSearchParams({
                target_date_from: commonFnc.formatDate(serchCondition.target_date_from),
                target_date_to: commonFnc.formatDate(serchCondition.target_date_to)
            });
        const res = await fetch(`/api/select/allUser_term_kintai_bsc?${query_kintai}`);
        const data = await res.json();
        setKintaiList(data);
    }

    const getShiftTable = async () =>{
        // すべての関数を並行して実行し、それが完了するまで待機する
        await Promise.all([
          getDepartmentMst(),
          getShiftMst(),
          getKintaiList(),
          getUsers(),
        ]);
        createColumnData();
    }

    useEffect(()=>{
        getDepartmentMst();
        getShiftMst();
        getUsers();
    },[])

    useEffect(()=>{
        createRowData(kintaiList);
    },[kintaiList])

    useEffect(()=>{
        createColumnData();
    },[shiftHash])

    useEffect(()=>{
        let tmpShiftHash = {}
        let tmpShiftStyleList = {}
        Shift_msts.map((Shift_mst) => {
            tmpShiftHash[Shift_mst.shift_type_cd] = Shift_mst.shift_name;

            tmpShiftStyleList[Shift_mst.shift_type_cd] = {
                backgroundColor:Shift_mst.backgroundcolor,
                color:Shift_mst.textcolor,
            }

        });
        setShiftHash(tmpShiftHash);
        setShiftStyleList(tmpShiftStyleList);
    },[Shift_msts]);

    
    useEffect(()=>{
        let tmpUserHash = {}
        Users.map((user) => {
            tmpUserHash[user.user_id] = {...user}
        });
        setUserHash(tmpUserHash);
    },[Users]);
    
    useEffect(()=>{
        let tmpDeptHash = {}
        Department_msts.map((dept) => {
            tmpDeptHash[dept.department_cd] = {...dept}
        });
        setDeptHash(tmpDeptHash);
    },[Department_msts]);
    
    useEffect(()=>{
        setSerchCondition({
            ...serchCondition,
            target_deptgr_cd: Login_user_info?.organization_cd, 
            target_dept_cd: Login_user_info?.department_cd,
        })
    },[]);
    


    /**
     * 開始日と終了日を指定して日付配列を作成する。
     * @param {*} startDate 
     * @param {*} endDate 
     * @returns 
     */
    function getDatesBetween(startDate, endDate) {
        let dates = [];
        let currentDate = new Date(startDate);
        
        while (currentDate <= new Date(endDate)) {
            dates.push(commonFnc.formatDate(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        
        return dates;
    }

    /**
     * IDごとに日付を横持ちにする。
     * @param {*} data 
     * @returns 
     */
    function transformShiftData(data) {
        const result = {};
        
        const tmpData = serchCondition.target_dept_cd ? data.filter(item=>userHash[item.user_id]?.department_cd === serchCondition.target_dept_cd):data;
        tmpData.forEach(item => {
          const { user_id, date, shift_type_cd, harf_holiday_shift_type_cd, paid_holiday_shift_type_cd } = item;
          
          // 初めての user_id の場合、空のオブジェクトを作成
          if (!result[user_id]) {
            result[user_id] = { user_id };
            // シフト以外のデータを登録
            result[user_id]["user_name"] = userHash[item.user_id]?.first_name + " " + userHash[item.user_id]?.second_name;
            result[user_id]["organization_cd"] = userHash[item.user_id]?.organization_cd;
            result[user_id]["department_cd"] = userHash[item.user_id]?.department_cd;
          }
      
          // 日付をキーにして shift_type_cd を設定
          result[user_id][commonFnc.formatDate(date)] = {
            shift_type_cd:shift_type_cd,
            harf_holiday_shift_type_cd:harf_holiday_shift_type_cd,
            paid_holiday_shift_type_cd:paid_holiday_shift_type_cd,
          }

        });

      
        // オブジェクトを配列に変換
        return Object.values(result);
      }

    /**
     * user_idごとに指定した項目の合計を求める関数
     * @param {Array} data - {user_id, date, paid_holiday_time} のオブジェクト配列
     * @returns {Object} - user_idごとの paid_holiday_time の合計
     */
    function getTotalItem(data, item) {
        return data.reduce((acc, curr) => {
        // すでにuser_idが存在する場合は加算、存在しない場合は新規作成
        if (acc[curr.user_id]) {
            acc[curr.user_id] += curr[item];
        } else {
            acc[curr.user_id] = curr[item];
        }
        return acc;
        }, {});
    }
    
    /**
     * row情報を作成する。
     * @param {*} kintaiList 
     */
    const createRowData = (kintaiList)=>{
        const uniqueUseridList = transformShiftData(kintaiList);
        const publicHolidayTimes = getTotalItem(kintaiList, 'public_holiday_time');
        const paidHolidayTimes = getTotalItem(kintaiList, 'paid_holiday_time');

        const tmpRow = uniqueUseridList.map(user => ({
            ...user,
            total_public_holiday_time: publicHolidayTimes[user.user_id] || 0, // 該当しない場合は0を設定
            total_paid_holiday_time: paidHolidayTimes[user.user_id] || 0, // 該当しない場合は0を設定
          }));

          tmpRow.sort((a, b) => {
            if (a.organization_cd !== b.organization_cd) {
              return a.organization_cd - b.organization_cd;
            } else if (a.department_cd !== b.department_cd) {
              return a.department_cd - b.department_cd;
            } else {
              return a.user_id - b.user_id;
            }
          });
        setRows(tmpRow);
    }

    
    /**
     * column情報を作成する。
     */
    const createColumnData = () => {
        function formatDate(dateString) {
            const date = new Date(dateString);
            const month = date.getMonth() + 1; // 月は0から始まるため+1
            const day = date.getDate();
            return `${month}/${day}`;
          }

        const dates = getDatesBetween(serchCondition.target_date_from, serchCondition.target_date_to);

        let tmpColumns = [];
      
        dates.forEach(date => {
            let item ={};
          
            item["field"] = date;
            item["headerName"] = formatDate(date);
            item["width"] = 40;
            item["display"] = 'flex';
            item["headerAlign"] = 'center';
            item["align"] = 'center';
            item["justifyContent"] = 'center';
            item["valueGetter"] = (params) => (shiftHash[params.value.shift_type_cd] + (params.value.harf_holiday_shift_type_cd?("/" + shiftHash[params.value?.harf_holiday_shift_type_cd]):""));
            
            
            tmpColumns.push(item);
        });

        setColumns(tmpColumns);

    }


    /**
     * Styleの設定
     */
    const style = {
        tableRowStyle:{
            // padding:'0px',
            borderBottom: '2px solid black',
        },
        tableCellStyle:{
            minWidth:'38px',
            height:'30px',
            padding:'0px',
            textAlign : 'center',
            overflow : 'hidden',
            borderBottom: 'none',
        },
        tableCellBorderBottom: (rowIndex) => ({
            borderBottom: rowIndex % elements.length === 0 ? '1px solid black' : 'none',
            // borderBottom: 'none',
        }),
        tableCellSticky: (left) => ({
            position: "sticky",
            zIndex: 10,
            left: left,
            backgroundColor: '#FFF',
        }),
    }

    return (
        <Box>
            <Box>
                {isMobile?
                <SP_ShiftTableHeader
                    isMobile={isMobile}
                    Shift_msts={Shift_msts}
                    Department_msts={Department_msts}
                    serchCondition={serchCondition}
                    setSerchCondition={setSerchCondition}
                    getShiftTable={getShiftTable}
                    Login_user_info={Login_user_info}
                    isViewDept={isViewDept}
                    setIsViewDept={setIsViewDept}
                    isViewPaidHoliday={isViewPaidHoliday}
                    setIsViewPaidHoliday={setIsViewPaidHoliday}
                    TIME_PAID_HOLIDAY_FLG={TIME_PAID_HOLIDAY_FLG}
                    setRows={setRows}
                />:
                <ShiftTableHeader
                    isMobile={isMobile}
                    Shift_msts={Shift_msts}
                    Department_msts={Department_msts}
                    serchCondition={serchCondition}
                    setSerchCondition={setSerchCondition}
                    getShiftTable={getShiftTable}
                    Login_user_info={Login_user_info}
                    isViewDept={isViewDept}
                    setIsViewDept={setIsViewDept}
                    isViewPaidHoliday={isViewPaidHoliday}
                    setIsViewPaidHoliday={setIsViewPaidHoliday}
                    TIME_PAID_HOLIDAY_FLG={TIME_PAID_HOLIDAY_FLG}
                    setRows={setRows}
                />
                }
            </Box>
            {rows===null ? <Box className="loader">Loading...</Box> :
            <Box>
                <TableContainer component={Paper} 
                    style={{ 
                        maxHeight: isMobile?'70vh':'50vh',
                        overflow: 'auto' 
                    }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow sx={style.tableRowStyle}>
                                {isViewDept?<>
                                <TableCell 
                                    sx={{
                                        ...style.tableCellStyle,
                                        ...style.tableCellBorderBottom(elements.length),
                                        ...(isMobile?{}:{...style.tableCellSticky(0), zIndex: 30,}),
                                        minWidth:'180px'
                                    }}
                                    rowSpan={2}>
                                    施設
                                </TableCell>
                                <TableCell 
                                    sx={{
                                        ...style.tableCellStyle,
                                        ...style.tableCellBorderBottom(elements.length),
                                        ...(isMobile?{}:{...style.tableCellSticky(180), zIndex: 30,}),
                                        minWidth:'100px'
                                    }}
                                    rowSpan={2}>
                                    部署
                                </TableCell>
                                </>:<></>}
                                <TableCell 
                                    sx={{
                                        ...style.tableCellStyle,
                                        ...style.tableCellBorderBottom(elements.length),
                                        ...(isMobile?{}:{...style.tableCellSticky(280-(isViewDept?0:280)), zIndex: 30,}),
                                        minWidth:'100px'
                                    }}
                                    rowSpan={2}>
                                    職員番号
                                </TableCell>
                                <TableCell 
                                    sx={{
                                        ...style.tableCellStyle,
                                        ...style.tableCellBorderBottom(elements.length),
                                        ...(isMobile?{}:{...style.tableCellSticky(380-(isViewDept?0:280)), zIndex: 30,}),
                                        borderRight:'1px solid gray',
                                        marginRight:5,
                                        minWidth:'200px'
                                    }}
                                    rowSpan={2}>
                                    氏名
                                </TableCell>
                                <TableCell 
                                    sx={{
                                        ...style.tableCellStyle,
                                        ...style.tableCellBorderBottom(elements.length),
                                        ...(isMobile?{}:{...style.tableCellSticky(380-(isViewDept?0:280)), zIndex: 30,}),
                                        borderRight:'1px solid gray',
                                        marginRight:5,
                                        minWidth:'40px'
                                    }}
                                    rowSpan={2}>
                                    公休
                                </TableCell>
                                <TableCell 
                                    sx={{
                                        ...style.tableCellStyle,
                                        ...style.tableCellBorderBottom(elements.length),
                                        ...(isMobile?{}:{...style.tableCellSticky(380-(isViewDept?0:280)), zIndex: 30,}),
                                        borderRight:'1px solid gray',
                                        marginRight:5,
                                        minWidth:'40px'
                                    }}
                                    rowSpan={2}>
                                    有休
                                </TableCell>
                                {columns.map((column,index)=>(
                                    <TableCell 
                                        sx={{
                                            ...style.tableCellStyle,
                                            ...style.tableCellBorderBottom(1),
                                            ...commonFnc. getStyleForDate(column.field, holidays),
                                            ...isMobile?{}:{zIndex: 20,}
                                            }}>
                                        {column.headerName}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow sx={style.tableRowStyle}>
                                {columns.map((column,index)=>(
                                    <TableCell 
                                        sx={{
                                            ...style.tableCellStyle,
                                            ...style.tableCellBorderBottom(elements.length),
                                            ...commonFnc. getStyleForDate(column.field, holidays),
                                            top:30,
                                            ...isMobile?{}:{zIndex: 20,}
                                            }}>
                                        {commonFnc.getDayOfWeek(column.field)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row,index)=>(
                                elements.map((element, rowIndex)=>(
                                    <TableRow sx={style.tableRowStyle} hover>
                                        {rowIndex===0?<>
                                            {isViewDept?<>
                                            <TableCell 
                                                sx={{
                                                    ...style.tableCellStyle,
                                                    ...style.tableCellBorderBottom(rowIndex),
                                                    ...(isMobile?{}:style.tableCellSticky(0)),
                                                }} 
                                                rowSpan={elements.length}
                                            >
                                                {deptHash[row["organization_cd"]].name}
                                            </TableCell>
                                            <TableCell 
                                                sx={{
                                                    ...style.tableCellStyle,
                                                    ...style.tableCellBorderBottom(rowIndex),
                                                    ...(isMobile?{}:style.tableCellSticky(180)),
                                                }} 
                                                rowSpan={elements.length}
                                            >
                                                {deptHash[row["department_cd"]].name}
                                            </TableCell>
                                            </>:<></>}
                                            <TableCell 
                                                sx={{
                                                    ...style.tableCellStyle,
                                                    ...style.tableCellBorderBottom(rowIndex),
                                                    ...(isMobile?{}:style.tableCellSticky(280-(isViewDept?0:280))),
                                                }} 
                                                rowSpan={elements.length}
                                            >
                                                {row["user_id"]}
                                            </TableCell>
                                            <TableCell 
                                                sx={{
                                                    ...style.tableCellStyle,
                                                    ...style.tableCellBorderBottom(rowIndex),
                                                    ...(isMobile?{}:style.tableCellSticky(380-(isViewDept?0:280))),
                                                    borderRight:'1px solid gray',
                                                    marginRight:5
                                                }} 
                                                rowSpan={elements.length}
                                            >
                                                {row["user_name"]}
                                            </TableCell>
                                            <TableCell 
                                                sx={{
                                                    ...style.tableCellStyle,
                                                    ...style.tableCellBorderBottom(rowIndex),
                                                    ...(isMobile?{}:style.tableCellSticky(380-(isViewDept?0:280))),
                                                    borderRight:'1px solid gray',
                                                    marginRight:5
                                                }} 
                                                rowSpan={elements.length}
                                            >
                                                {row["total_public_holiday_time"]/8}
                                            </TableCell>
                                            <TableCell 
                                                sx={{
                                                    ...style.tableCellStyle,
                                                    ...style.tableCellBorderBottom(rowIndex),
                                                    ...(isMobile?{}:style.tableCellSticky(380-(isViewDept?0:280))),
                                                    borderRight:'1px solid gray',
                                                    marginRight:5
                                                }} 
                                                rowSpan={elements.length}
                                            >
                                                {row["total_paid_holiday_time"]/8}
                                            </TableCell>
                                        </>:<></>}
                                        {columns.map((column,columnIndex)=>(
                                            row[column.field]?
                                            <TableCell sx={{
                                                ...style.tableCellStyle,
                                                color:shiftStyleList[row[column.field][element]]?.color,
                                                backgroundColor:shiftStyleList[row[column.field][element]]?.backgroundColor,
                                                borderBottom:rowIndex===elements.length-1?'1px solid black':'none',
                                                }}>
                                                {shiftHash[row[column.field][element]]}
                                            </TableCell>
                                            :<TableCell
                                                sx={{borderBottom:rowIndex===elements.length-1?'1px solid black':'none',}}
                                            ></TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
        </Box>
    );
}

export default ShiftTableContents;